import { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import "./index.css";
import { ThemeProviderWrapper } from "./themes/ThemeProviderWrapper";
import { URLS } from "./urls";

// AUTH FUNCTIONS
import {
  LogInRequired,
  AccessRequired,
  IntakeRequired,
  AlreadyLoggedIn,
  EditPageCheck,
  NotPseudo
} from "./components/authentication";

const MasterPage = lazy(() => import("./pages/master"));

// AUTH PAGES
const LoginPage = lazy(() => import("./pages/client/login"));
const RegistrationPage = lazy(() => import("./pages/client/registration"));
const EmailVerified = lazy(() => import("./pages/client/emailVerified"));
const ResetPassword = lazy(() => import("./pages/client/passwordReset"));
const ErrorPage = lazy(() => import("./pages/error/not_found"));
const UnauthorizedPage = lazy(() => import("./pages/error/unauthorized"));

// CANDIDATE PAGES
const CandidateDashboardPage = lazy(() => import("./pages/client/dashboard"));
const PseudoMergeComponent = lazy(() => import('./pages/client/pseudoMerge'));
const IntakePage = lazy(() => import("./pages/client/intake"));
const MasterProfile = lazy(() => import("./pages/profile/MasterProfile"));
const ProjectStrengthsComponent = lazy(() => import("./pages/client/quizzes/projectStrengths"));
const PersonalityQuizComponent = lazy(() => import("./pages/client/quizzes/personalityQuiz"));
const Personality = lazy(() => import("./pages/client/personality"));

// STAFF PAGES
const AllPosts = lazy(() => import("./pages/staff/forums/allPosts"));
const PostPage = lazy(() => import("./pages/staff/forums/postPage"));

// ADMIN PAGES
const AdminDashboard = lazy(() => import("./pages/admin/dashboard"));
const AdminAnalyticsPage = lazy(() => import("./pages/admin/analytics"));
const AdminCandidateSearchPage = lazy(() => import("./pages/admin/candidateSearch"));
const ResumeParser = lazy(() => import("./pages/admin/resumeParser/Index"));

// PDF
const PDF = lazy(() => import("./pages/admin/PDF"));

// PUBLIC PAGES
const ClientPortal = lazy(() => import("./pages/admin/clientPortal"));
const ReferenceAssessment = lazy(() => import("./pages/public/referenceAssessment"));
const SingleUseProjectStrengths = lazy(() => import("./pages/client/quizzes/projectStrengths/singleUseProjectStrengths"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProviderWrapper>
      <CssBaseline enableColorScheme />
      <Suspense fallback={<></>}>
        <MasterPage>
          <Routes>
            <Route path="/" element={<AccessRequired><AdminDashboard /></AccessRequired>} />

            {/* AUTH PAGES */}
            <Route path="/login/:urlValue?" element={<AlreadyLoggedIn><LoginPage /></AlreadyLoggedIn>} />
            <Route path={URLS.AUTH.REGISTER} element={<AlreadyLoggedIn><RegistrationPage /></AlreadyLoggedIn>} />
            <Route path="/email_verified/:key" element={<EmailVerified />} />
            <Route path="/reset_password/:uid/:token" element={<ResetPassword />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path={URLS.AUTH.ERROR} element={<ErrorPage />} />
            <Route path={URLS.AUTH.UNAUTHORIZED} element={<UnauthorizedPage />} />

            {/* CANDIDATE PAGES */}
            <Route path={URLS.USER.DASHBOARD} element={<IntakeRequired><CandidateDashboardPage /></IntakeRequired>} />
            <Route path={URLS.AUTH.PSEUDO_MERGE} element={<NotPseudo><PseudoMergeComponent /></NotPseudo>} />
            <Route path={URLS.AUTH.INTAKE} element={<LogInRequired><IntakePage /></LogInRequired>} />
            <Route path="/profile/edit/:uuid" element={<EditPageCheck><MasterProfile /></EditPageCheck>} />
            <Route path="/profile/view/:uuid" element={<MasterProfile />} />
            <Route path="/profile/print/:uuid" element={<MasterProfile />} />
            <Route path="/project_strengths" element={<LogInRequired><ProjectStrengthsComponent /></LogInRequired>} />
            <Route path="/personality_quiz" element={<LogInRequired><PersonalityQuizComponent /></LogInRequired>} />
            <Route path="/personality/:uuid" element={<LogInRequired><Personality /></LogInRequired>} />

            {/* STAFF PAGES */}
            <Route path={'/forums/all'} element={<AccessRequired><AllPosts /></AccessRequired>} />
            <Route path="/forums/post/:id" element={<AccessRequired><PostPage /></AccessRequired>} />

            {/* ADMIN PAGES */}
            <Route path={URLS.HOME} element={<AccessRequired><AdminDashboard /></AccessRequired>} />
            <Route path={URLS.ADMIN.REPORTS} element={<AccessRequired><AdminAnalyticsPage /></AccessRequired>} />
            <Route path="/job/:job_uuid/:bucket?" element={<AccessRequired><AdminCandidateSearchPage /></AccessRequired>} />
            <Route path="resume_parser/:user_uuid" element={<ResumeParser />} />

            {/* PDF */}
            <Route path="/pdf/edit/:user_uuid" element={<PDF />} />

            {/* PUBLIC PAGES */}
            <Route path="/client_portal/:job_uuid" element={<ClientPortal />} />
            <Route path={URLS.PUBLIC.REFERENCE_ASSESSMENT} element={<ReferenceAssessment />} />
            <Route path="/project_strengths_quiz" element={<SingleUseProjectStrengths />} />
          </Routes>
        </MasterPage>
      </Suspense>
    </ThemeProviderWrapper>
  </BrowserRouter>
  // </React.StrictMode>
);